@import '../config/index.scss';

html,
body {
  height: 100%;
}

.wrapper {
  --dsx-header-height: #{rem(56px)};
  --dsx-header-background-color: var(--dsx-color-primary-fill-default);
  --dsx-aside-width: #{rem(428px)};
  --dsx-aside-background-color: var(--dsx-color-primary-surface-default);
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

/* 챗팅 global style p 덮어쓰기 */
.wrapper p,
.read-preview p {
  margin: 0;
}

.header {
  min-height: var(--dsx-header-height);
  background-color: var(--dsx-header-background-color);
}
.gnb {
  --dsx-gnb-spacer: 12px;
  --dsx-gnb-menu-gap: 40px;
  --dsx-gnb-control-size: #{rem(32px)};
  position: relative;
  @include flex(flex, flex-start, center);
  height: var(--dsx-header-height);
  color: var(--dsx-color-static-white);
  margin: 0 24px;
  padding: var(--dsx-gnb-spacer) 0;
  z-index: 1;
  .logo {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    height: rem(24px);
    img {
      height: 100%;
    }
    .logo-name {
      font-family: 'Manrope', sans-serif;
      font-size: rem(20px);
      font-weight: var(--dsx-font-weight-bold);
    }
  }
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    @include flex(flex, center, center);
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .allmenu {
    position: absolute;
    top: var(--dsx-gnb-spacer);
    left: 0;
  }
  .util {
    @include flex(flex, flex-end, center);
    gap: 16px;
    margin-left: auto;
  }
}

.profile-trigger {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  &::after {
    content: '';
    width: rem(20px);
    height: rem(20px);
    -webkit-mask-image: var(--dsx-icon-chevronBottom);
    -webkit-mask-size: 100%;
    background-color: currentColor;
  }
  .dsx-Avatar {
    filter: none;
  }
}
.profile-popover {
  width: rem(280px);
  padding: 20px;
  .profile-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .user-name {
      display: flex;
      gap: 4px;
      margin-top: 16px;
      .dsx-Badge {
        font-size: var(--dsx-font-size-large);
      }
    }
    .user-team {
      margin-top: 12px;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  gap: 8px;
  .user-avatar {
    border-radius: 100%;
    box-shadow: inset 0 0 0 1px var(--ds-color-border-subtler);
  }
  .name {
    color: var(--ds-color-text-strong);
    font-size: var(--ds-font-size-lg);
  }
  .profile-desc {
    flex-direction: column;
    display: flex;
  }
}

.container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 100%;
  height: calc(100vh - var(--dsx-header-height));
  overflow: hidden;
}
.chat-area,
.page-area {
  height: 100%;
}

@include mobile {
  .gnb {
    --dsx-gnb-menu-gap: 10px;
  }
}
