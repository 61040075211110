
@import '../config/index.scss';

.dsx-Card {
  --dsx-card-content-spacing: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: var(--dsx-radius-xlarge);
  box-shadow: inset 0 0 0 1px var(--dsx-color-neutral-border-alternative);
  overflow: hidden;
  &-media {
    @include flex(flex, center, center);
    width: var(--dsx-card-media-size);
    background-color: var(--dsx-color-info-fill-neutral);
    aspect-ratio: 1.5;
    :where(img, iframe, video) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content {
    flex: 1;
    padding: var(--dsx-card-content-spacing);
  }
  &-float {
    position: absolute;
    top: var(--dsx-card-content-spacing);
    right: var(--dsx-card-content-spacing);
    --dsx-icon-color: var(--dsx-color-neutral-icon-neutral);
  }

  // variant
  &--horizontal {
    --dsx-card-media-size: rem(200, 16);
    --dsx-card-content-spacing: 16px;
    flex-direction: row;
    @include mobile {
      --dsx-card-media-size: 30vw;
    }
  }
}