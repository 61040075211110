@import '../config/index.scss';

.studio-search,
.member-search {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  .search-input {
    --dsx-icon-size: #{rem(20px)};
    --dsx-icon-color: #81848c;
    width: rem(340px);
    height: rem(48px);
    padding: 0 var(--dsx-space-5);
    font-size: rem(15px);
    font-weight: var(--dsx-font-weight-medium);
    border-radius: var(--dsx-radius-max);
    background-color: rgba(112, 115, 124, 0.04);
    &:active,
    &:has(:focus-visible, [aria-expanded='true']) {
      outline: 2px solid var(--dsx-color-primary-text-default);
      outline-offset: -2px;
    }
  }
}

.studio-content {
  margin-top: 60px;
}

.category-bar {
  display: flex;
  align-items: center;
  margin-bottom: var(--dsx-space-7);
  .separ::before {
    content: '';
    display: inline-block;
    width: 1px;
    height: 20px;
    margin: 0 12px;
    background-color: var(--dsx-color-neutral-border-alternative);
    vertical-align: middle;
  }
  .side {
    margin-left: auto;
    // 250123 : AIONU 등록버튼 추가
    .regist-button-container {
      position: relative;
    }
    .add-button {
      display: flex;
      gap: 10px;
    }
  }
}

.setting-bar {
  display: flex;
  align-items: center;
  margin-bottom: var(--dsx-space-5);
  .side {
    margin-left: auto;
  }
}

.studio-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.studio-card-item {
  --dsx-card-item-radius: 16px;
  --dsx-card-item-spacer: 24px;
  --dsx-card-item-sub-height: #{rem(42px)};
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 230px;
  border-radius: var(--dsx-card-item-radius);
  border: 1px solid var(--dsx-color-neutral-border-alternative);
  transition: var(--dsx-transition-base);
  overflow: hidden;
  .card-body {
    flex: 1;
    position: relative;
    padding: var(--dsx-card-item-spacer) var(--dsx-card-item-spacer) 12px;
    border-radius: var(--dsx-card-item-radius) var(--dsx-card-item-radius) 0 0;
    .b-img {
      width: rem(24px);
      height: rem(24px);
      margin-bottom: 12px;
    }
    .b-tag {
      display: flex;
      gap: 6px;
    }
    .b-tit {
      min-height: rem(32px);
      margin-top: 8px;
      color: var(--dsx-color-neutral-text-default);
      font-size: rem(18px);
      @include ellipsis(2);
      &:first-child {
        margin-top: 0;
      }
    }
    .b-txt {
      margin: 4px 0 0 0;
      @include ellipsis(2);
    }
  }
  .card-foot {
    --dsx-icon-color: #e0e0e2;
    display: flex;
    align-items: center;
    height: var(--dsx-card-item-sub-height);
    padding: 11px var(--dsx-card-item-spacer);
    color: var(--dsx-color-neutral-text-alternative);
    font-size: rem(12px);
    line-height: 1.3;
    border-top: 1px solid #e8e9ea;
    border-radius: 0 0 var(--dsx-card-item-radius) var(--dsx-card-item-radius);
    .f-favorit {
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 4px;
      margin-left: -4px;
      .msg {
        position: absolute;
        left: rem(24px);
      }
    }
    .f-date {
      margin-left: auto;
    }
    .f-state {
      --dsx-icon-color: inhereit;
      --dsx-icon-size: #{rem(20px)};
      position: relative;
      display: inline-flex;
      align-items: center;
      padding: 0 4px;
      margin-left: -4px;
      font-size: var(--dsx-font-size-label2);
      font-weight: var(--dsx-font-weight-medium);
      .msg {
        position: absolute;
        left: rem(26px);
      }
      &.f-learning {
        color: #13bf24;
      }
      &.f-complete {
        color: var(--dsx-color-cyan-fill-default);
      }
      &.f-waiting {
        color: var(--dsx-color-primary-text-neutral);
      }
    }
  }
  .card-hover-link {
    position: absolute;
    bottom: -2px;
    left: -2px;
    right: -2px;
    @include flex(flex, center, center);
    height: calc(var(--dsx-card-item-sub-height) + 2px);
    color: var(--dsx-color-static-white);
    font-weight: var(--dsx-font-weight-bold);
    border-radius: 0 0 var(--dsx-card-item-radius) var(--dsx-card-item-radius);
    background-color: var(--dsx-color-primary-fill-default);
    transition: var(--dsx-transition-collapse);
    transform: translateY(100%);
    &:hover,
    &:focus-visible {
      background-color: var(--dsx-color-primary-icon-neutral);
    }
  }
  .card-float {
    --dsx-icon-color: #e0e0e2;
    --dsx-icon-size: #{rem(20px)};
    position: absolute;
    top: 23px;
    right: 23px;
    display: flex;
    align-items: center;
    gap: 6px;
    .add-msg {
      color: var(--dsx-color-interaction-text-inactive);
      font-size: var(--dsx-font-size-caption1);
      font-weight: var(--dsx-font-weight-medium);
    }
  }

  &.is-active,
  &:has(> :not(.card-foot):hover, > :not(.card-foot):focus-visible) {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    border-color: var(--dsx-color-primary-border-default);
    &.is-ready {
      .card-body {
        .b-tag,
        .b-tit {
          display: none;
        }
        .b-txt {
          @include ellipsis(5);
        }
      }
      .card-hover-link {
        transform: translateY(0);
      }
    }
    &.is-ready:has(.card-hover-link:hover, .card-hover-link:focus-visible) {
      border-color: var(--dsx-color-primary-icon-neutral);
    }
  }
  .card-regist {
    @include flex(flex, center, center, column);
    border-radius: inherit;
    height: 100%;
    gap: 10px;
    .r-tit {
      font-size: var(--dsx-font-size-title3);
      font-weight: var(--dsx-font-weight-bold);
    }
    .r-txt {
      display: inline-block;
      padding: 5px 10px;
      color: var(--dsx-color-neutral-text-neutral);
      font-size: 12px;
      font-weight: var(--dsx-font-weight-medium);
      border-radius: var(--dsx-radius-max);
      background-color: var(--dsx-color-primary-surface-default);
    }
    .r-icon {
      --dsx-icon-size: #{rem(20px)};
      width: rem(32px);
      height: rem(32px);
      color: var(--dsx-color-static-white);
      background: var(--dsx-color-primary-text-neutral);
      border-radius: 6px;
    }
  }
  &.is-disabled:hover {
    border-color: var(--dsx-color-interaction-dimmer);
    .r-icon {
      background: var(--dsx-color-interaction-dimmer);
    }
  }
  &.is-skeleton {
    border-color: #f4f4f5;
    @include skeleton;
  }
}

.check-bookmark {
  &[aria-selected='true'] {
    --dsx-icon-color: var(--dsx-color-primary-border-neutral);
  }
}

// 250225 aionu 연동
.read-detail {
  &.aionu-detail {
    .det-head {
      margin-bottom: 20px;
      &.center {
        align-items: center;
      }
    }
    .det-foot {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
      border-top: 1px solid #eaebec;
    }
  }
}

.aionu-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  max-height: 260px;
  overflow-y: auto;
  .aionu-card-item {
    --dsx-card-item-radius: 16px;
    --dsx-card-item-spacer: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    border-radius: var(--dsx-card-item-radius);
    border: 1px solid var(--dsx-color-neutral-border-alternative);
    transition: var(--dsx-transition-base);
    overflow: hidden;
    .card-body {
      flex: 1;
      position: relative;
      padding: var(--dsx-card-item-spacer);
      // .b-tag {
      //   .dsx-Badge {
      //     font-size: 0.75rem;
      //   }
      // }
      .b-tag + .b-tit {
        margin-top: 8px;
      }
      .b-tit {
        font-size: var(--dsx-font-size-large);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        display: block;
      }
      .b-txt {
        font-size: 13px;
        margin: 4px 0 0 0;
        display: -webkit-box;
        max-height: 3em;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    &.is-active,
    &:has(> :not(.card-foot):hover, > :not(.card-foot):focus-visible) {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
      border-color: var(--dsx-color-primary-border-default);
    }

    &.disabled {
      &:has(> :not(.card-foot):hover, > :not(.card-foot):focus-visible) {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        border-color: var(--dsx-color-negative-border-default);
      }
      .card-disabled-message {
        cursor: default;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end; // 하단 정렬
        justify-content: center;
        padding: var(--dsx-card-item-spacer);
        border-radius: var(--dsx-card-item-radius);
        background-color: rgba(255, 255, 255, 0.5); // 반투명한 배경
        backdrop-filter: blur(1px);
        z-index: 1;
        .dsx-Badge {
          height: auto;
          line-height: 1.4;
        }
      }
    }
    &.is-skeleton {
      border-color: #f4f4f5;
      @include skeleton;
    }
  }
}
