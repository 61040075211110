@import '../config/index.scss';

.studio-wrapper {
  // 25.02.18 상단 프로필로, 이중 스크롤 생겨서 처리
  height: 100vh;
  overflow: hidden;
}

.create-wrapper {
  --dsx-create-header-height: #{rem(56px)};
  position: relative;
  width: 100%;
  height: 100%;
}
.create-header {
  @include flex(flex, flex-start, center);
  gap: var(--dsx-space-4);
  padding: var(--dsx-space-3) var(--dsx-space-10);
  height: var(--dsx-create-header-height);
  border-bottom: 1px solid var(--dsx-color-neutral-border-alternative);
  & > .dsx-IconButton {
    margin: 0;
    border: 1px solid var(--dsx-color-neutral-border-neutral);
    border-radius: var(--dsx-radius-large);
  }
  .h-side {
    display: flex;
    align-items: center;
    gap: var(--dsx-space-3);
    margin-left: auto;
  }
}
.create-container {
  position: relative;
  display: flex;
  height: calc(100vh - var(--dsx-create-header-height));
  overflow: hidden;
  .create-form,
  .create-test {
    width: 50%;
    flex: 1 50%;
    position: relative;
    height: 100%;
  }
}
.create-delete.ds-control .ds-icon {
  background-color: #aeb0b6;
}
.create-form {
  display: flex;
  flex-direction: column;
  .form-step {
    flex: 0 0 auto;
    @include flex(flex, center, center);
    height: rem(64px);
    border-bottom: 1px solid var(--dsx-color-neutral-border-alternative);
  }

  .form-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    overflow-y: auto;
    & > .dsx-ButtonArea {
      margin-top: auto;
      padding-top: 20px;
    }
    // .dsx-File--drag &[data-drag='false'] {
    //   border: none;
    //   background-color: #f8f9fb;
    // }
  }

  .aionu-form {
    height: 100%; /* 또는 특정 높이값 */
    display: flex;
    flex-direction: row;
    overflow-y: auto; // 스크롤을 여기서만 적용
    .form-panel {
      // min-height: 100%; // 최소 높이 설정
      height: calc(100vh + var(--dsx-create-header-height));
      padding: 0px;
      margin: 40px;
      overflow-y: hidden;
    }
  }
}
.form-avatar {
  margin-bottom: 40px;
  .dsx-Avatar {
    margin: 0 auto;
  }
}
.step-list {
  counter-reset: step 0;
  display: inline-flex;
  align-items: center;
  & > li {
    display: inline-flex;
    align-items: center;
    &:not(:last-child)::after {
      content: '';
      display: inline-flex;
      width: rem(24px);
      height: rem(24px);
      margin: 0 var(--dsx-space-5);
      -webkit-mask: var(--dsx-icon-chevron) 50% 50% no-repeat;
      -webkit-mask-size: 100%;
      background-color: #aeb0b6;
    }
    .step-name {
      display: inline-flex;
      padding: var(--dsx-space-4) var(--dsx-space-5);
      color: var(--dsx-color-interaction-text-inactive);
      font-size: var(--dsx-font-size-body2);
      font-weight: var(--dsx-font-weight-medium);
      border-radius: var(--dsx-radius-xxlarge);
      &::before {
        content: counter(step);
        counter-increment: step 1;
        @include flex(inline-flex, center, center);
        width: rem(24px);
        height: rem(24px);
        margin-right: var(--dsx-space-3);
        font-size: var(--dsx-font-size-caption1);
        border-radius: var(--dsx-radius-max);
        background-color: var(--dsx-color-interaction-fill-inactive);
      }
    }
  }
  & > li.is-active {
    .step-name {
      background-color: var(--dsx-color-primary-fill-neutral);
      color: var(--dsx-color-primary-text-default);
      font-weight: var(--dsx-font-weight-semibold);
      &::before {
        color: var(--dsx-color-inverse-text);
        background-color: var(--dsx-color-primary-fill-default);
      }
    }
  }
  & > li.is-complete {
    .step-name {
      color: var(--dsx-color-normal-fill-default);
      font-weight: var(--dsx-font-weight-semibold);
      &::before {
        content: '';
        color: var(--dsx-color-inverse-text);
        background: var(--dsx-color-static-white) var(--dsx-icon-check) 50% 50% no-repeat;
        filter: invert(1) opacity(0.72);
      }
    }
  }
  & > li.selectable {
    cursor: pointer;
  }
}

.create-test {
  --dsx-create-test-background-color: var(--dsx-color-primary-surface-default);
  display: flex;
  flex-direction: column;
  background-color: var(--dsx-create-test-background-color);
}
.plugin-profile {
  flex: 1;
  @include flex(flex, center, center, column);
  gap: 12px;
  text-align: center;
  .dsx-Text {
    margin-top: 4px;
  }
}
.view-info {
  @include flex(flex, center, center, column);
  flex: 0 0 auto;
  color: var(--dsx-color-neutral-text-alternative);
  height: rem(64px);
  text-align: center;
  background-color: var(--dsx-color-neutral-fill-alternative);
  &--primary {
    color: var(--dsx-color-primary-text-default);
    background-color: var(--dsx-color-info-fill-neutral);
  }
  &--neutral {
    color: var(--dsx-color-neutral-text-neutral);
    border-bottom: 1px solid #eaebec;
    background-color: var(--dsx-color-neutral-surface);
  }
}
.view-chat {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 var(--dsx-space-11);
  overflow-y: auto;
  .dsx-Empty {
    flex: 1;
  }
  .chat-bottom {
    margin: auto calc(var(--dsx-space-11) * -1) 0;
    padding: 0 var(--dsx-space-11) var(--dsx-space-10);
    background-color: var(--dsx-create-test-background-color);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 80px;
      margin-top: -80px;
      background: linear-gradient(0deg, var(--dsx-create-test-background-color) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .btn-reset {
      height: rem(36px);
      padding: 8px 16px;
      color: var(--dsx-color-neutral-text-default);
      border-radius: var(--dsx-radius-max);
      background-color: var(--dsx-color-neutral-fill-alternative);
    }
  }
  .chat-input {
    flex-direction: row;
    align-items: flex-end;
    border-radius: 16px;
    background-color: var(--dsx-color-white);
    &:has(textarea:disabled) {
      background-color: transparent;
    }
    .input-text {
      min-height: rem(32px);

      // ios설정
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      -webkit-user-select: text;
    }
    .btn-reset {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, calc(-100% - 16px));
    }
  }
  .chat-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(60px);
    padding: 12px 20px 12px 24px;
    color: var(--dsx-color-neutral-text-alternative);
    font-weight: var(--dsx-font-weight-medium);
    background-color: var(--dsx-color-neutral-fill-alternative);
    border-radius: calc(var(--dsx-radius-base) * 4);
    .btn-reset {
      background-color: var(--dsx-color-primary-surface-default);
    }
  }
  .chat-warning {
    --dsx-icon-color: #d9d9d9;
    @include flex(flex, center, center);
    gap: 4px;
    margin-top: 16px;
    font-size: 12px;
    color: var(--dsx-color-neutral-text-alternative);
    .txt-length {
      margin-left: auto;
    }
  }
  .plugin-profile {
    padding: 32px 0 80px;
  }
}

.form-tooltip {
  min-width: rem(340px);
  margin-top: -6px;
  padding: 12px;
  text-align: center;
  &::before {
    display: none;
  }
  .tooltip-head {
    margin: 4px 0 12px;
    .dsx-Text {
      margin-top: 12px;
    }
  }
  .tooltip-body {
    padding: 20px;
    background-color: #f7fbff;
    border-radius: var(--dsx-radius-large);
    .dsx-Text:not(:first-child) {
      margin-top: 8px;
    }
  }
  .tooltip-point {
    margin: 16px 0;
    padding: 8px;
    color: var(--dsx-color-primary-text-default);
    font-weight: var(--dsx-font-weight-semibold);
    border-radius: 6px;
    background-color: #e8f1ff;
  }
}

/* 대화 스타터 */
.form-questions {
  .question-input {
    position: relative;
    margin-top: 8px;
    padding-right: rem(32px);
    &:first-child {
      margin-top: 0;
    }
    .q-del {
      position: absolute;
      top: 12px;
      right: 0;
      color: var(--dsx-color-normal-icon-neutral);
    }
  }
}

.alert-box {
  margin-top: 12px;
  padding: 20px;
  font-size: var(--dsx-font-size-body2);
  background-color: var(--dsx-color-primary-surface-default);
  border-radius: var(--dsx-radius-large);
  .tit {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-right: 34px;
    color: var(--dsx-color-nuetral-text-neutral);
    font-weight: var(--dsx-font-weight-medium);
    vertical-align: middle;
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      -webkit-mask: var(--dsx-icon-successFill) 50% 50% no-repeat;
      -webkit-mask-size: 100%;
      background-color: var(--dsx-color-info-fill-default);
    }
  }
  .con {
    font-weight: var(--dsx-font-weight-semibold);
    vertical-align: middle;
  }
}

/* 프로필 이미지 등록 팝업 */
.dialog-profiles {
  width: 480px;
  padding: 0;
  gap: 0;
  border-radius: calc(var(--dsx-radius-base) * 5);

  .dsx-Heading {
    padding: var(--dsx-space-7) 0 var(--dsx-space-5);
    text-align: center;
  }
  .dsx-Dialog-footer {
    padding: var(--dsx-space-4) 0 var(--dsx-space-5);
    justify-content: center;
    border-top: 1px solid var(--dsx-color-neutral-border-alternative);
  }

  /* Tab 스타일 */
  .dsx-TabList {
    margin-bottom: 0;
    gap: 0px;
  }
  .dsx-Tab {
    flex: 1;
    font-size: var(--dsx-font-size-body2) !important;
  }
  .dsx-TabPanel {
    height: 344px;
    overflow-y: auto;
  }

  /* 공통 */
  .grey-box {
    padding: var(--dsx-space-4);
    gap: var(--dsx-space-4);
    border-radius: var(--dsx-radius-xlarge);
    background-color: #f8f9fb;
  }

  [class*='-img'] > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profiles {
  /* Tab-1 */
  &-emoji {
    padding: var(--dsx-space-9);
    display: grid;
    grid-template-columns: repeat(4, 80px);
    justify-content: center;
    gap: var(--dsx-space-6) var(--dsx-space-7);

    .emoji-item {
      @include flex(inline-flex, center, center);
      aspect-ratio: 1;
      font-size: rem(44px);
      border-radius: var(--dsx-radius-max);
      background-color: var(--dsx-color-normal-fill-default);
      transition: border var(--dsx-transition-base);
      outline-offset: 2px;
      cursor: pointer;

      &.is-selected,
      &[aria-selected='true'] {
        position: relative;
        box-shadow: 0 0 0 3px var(--dsx-color-primary-border-default);
        &::before,
        &::after {
          @include layout($t: 0, $r: 0);
          @include el-pseudo(24px, 24px);
        }
        &::before {
          border-radius: var(--dsx-radius-max);
          background-color: var(--dsx-color-primary-icon-default);
        }
        &::after {
          mask-image: var(--dsx-icon-check);
          mask-size: 100%;
          background-color: var(--dsx-color-static-white);
        }
      }
    }
  }

  /* Tab-2 */
  &-ai {
    padding: var(--dsx-space-7);
    @include flex($d: column);
    gap: var(--dsx-space-6);

    /* [생성전] */
    .exam-item {
      position: relative;
      width: calc(33.3% - 0.5rem);
      aspect-ratio: 1;
      border-radius: var(--dsx-radius-large);
      overflow: hidden;
      &::after {
        @include layout($t: 0, $l: 0);
        @include el-pseudo(100%, 100%);
        background-image: -webkit-linear-gradient(rgba(#000, 0), rgba(#000, 70%));
        background: -ms-linear-gradient(rgba(#000, 0), rgba(#000, 70%));
        background: linear-gradient(rgba(#000, 0), rgba(#000, 70%));
      }
      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p {
        @include layout($l: 12px, $r: 12px, $b: 12px, $i: 1);
        color: var(--dsx-color-static-white);
      }
    }

    /* [생성후] */
    .ai-thumb {
      --ai-thumb-size: 128px;
      --ai-thumb-radius: var(--dsx-radius-max);
      @include flex($v: center, $h: center, $d: column);

      &-img {
        width: var(--ai-thumb-size);
        aspect-ratio: 1;
        display: block;
        border-radius: var(--ai-thumb-radius);
        overflow: hidden;
        transition: width var(--dsx-transition-base);
      }
      &-close .dsx-Icon {
        font-size: rem(40px);
      }
      &--full {
        --ai-thumb-size: calc(100% - 90px);
        --ai-thumb-radius: calc(var(--dsx-radius-xlarge) * 3);

        @include layout($t: 0, $l: 0, $r: 0, $b: 0);
        gap: var(--dsx-space-6);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border-radius: var(--dsx-radius-xlarge);
        z-index: 1;
        overflow: hidden;
      }
    }

    /* 공통 */
    .ai-cont {
      @include flex($w: wrap, $d: column);
      gap: 8px;
      &-title {
        @include flex($h: space-between);
      }
      .btn-recreate {
        margin-left: auto;
      }
      .dsx-Text--error {
        margin-top: -8px;
      }
    }

    .ai-item {
      --dsx-checkbox-margin: 0;
      --ai-item-border: none;

      position: relative;
      width: calc((100% - 48px) * 0.2);
      display: block;

      &:has(:checked) {
        --ai-item-border: 0 0 0 3px var(--dsx-color-primary-border-default);
      }
      .dsx-Checkbox-input {
        @include layout($t: 4px, $r: 4px);
        &:not(:checked) {
          display: none;
        }
      }
      .dsx-Checkbox-label {
        padding: 0;
        display: block;
        text-align: center;
      }

      &-img {
        width: 100%;
        aspect-ratio: 1;
        display: block;
        border-radius: var(--dsx-radius-large);
        box-shadow: var(--ai-item-border);
        overflow: hidden;
      }
    }
  }

  /* Tab-3 */
  &-img {
    height: 100%;
    padding: var(--dsx-space-7);
    p {
      margin: 0;
    }
    .dsx-FileUpload {
      height: 100%;
    }
    .dsx-File--drag {
      height: inherit;
      justify-content: center;
      img {
        width: 40px;
      }
      // &[data-drag='false'] {
      //   border: none;
      //   background-color: #f8f9fb;
      // }
    }

    .grey-box {
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: rem(24px);
    }
    .upload-img {
      width: 160px;
      // max-height: 160px;
      // height: 160px;
      display: block;
      aspect-ratio: 1;
      border-radius: var(--dsx-radius-max);
      border: 1px solid var(--dsx-color-neutral-border-neutral);
      overflow: hidden;
    }
  }
}

/* AI 생성중 */
.works-loading {
  width: 100%;
  height: 100%;
  @include flex($v: center, $h: center, $d: column);
  gap: var(--dsx-space-4);

  .loading {
    // width: 60px;
    @include flex($v: center, $h: center);
    aspect-ratio: 1;
    border-radius: var(--dsx-radius-max);
    background-color: var(--dsx-color-primary-fill-neutral);
    &::before {
      @include el-pseudo(30px, 22px);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37' height='21' viewBox='0 0 37 21' fill='none'%3E%3Cpath d='M18.8354 14.1407C18.1455 15.352 17.5429 16.6063 16.7547 17.7316C16.1656 18.5739 15.4167 19.3511 14.6026 19.9796C12.4763 21.6213 9.74872 21.0248 8.39475 18.7018C7.41464 17.022 6.4505 15.3323 5.47899 13.6475C4.04755 11.1646 2.59889 8.69158 1.19082 6.19517C0.401313 4.7957 0.443125 3.3532 1.28428 1.99308C2.1033 0.671089 3.35397 0.0193159 4.90347 0.0734253C6.45173 0.127535 7.61386 0.882608 8.39106 2.23288C10.291 5.53233 12.1972 8.82932 14.1021 12.1263C14.3677 12.585 14.6346 13.0437 14.9445 13.5774C15.7008 12.3514 16.1558 11.1068 16.2099 9.73073C16.216 9.56594 16.1066 9.38517 16.018 9.23022C15.4511 8.23288 14.8608 7.25031 14.3074 6.24559C13.197 4.2337 13.8819 1.75205 15.836 0.646494C17.8196 -0.477507 20.2816 0.166887 21.4413 2.16156C23.8996 6.3907 26.3603 10.6198 28.762 14.881C30.0545 17.1757 28.826 20.0275 26.3247 20.758C24.4948 21.2929 22.5813 20.5452 21.611 18.8961C20.7809 17.4844 19.9717 16.0603 19.1515 14.6424C19.042 14.453 18.919 14.271 18.8379 14.1419L18.8354 14.1407Z' fill='%231A75FF'/%3E%3Cpath d='M32.5217 0.0703125C30.2552 0.0703125 28.418 1.90757 28.418 4.17402C28.418 6.44047 30.2552 8.27773 32.5217 8.27773V11.0152C32.5217 11.0152 36.6254 8.58148 36.6254 4.17402C36.6254 1.90757 34.7881 0.0703125 32.5217 0.0703125Z' fill='%231A75FF'/%3E%3C/svg%3E");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      mask-image: linear-gradient(to top, transparent 50%, black 50%);
      mask-size: 100% 200%;
      animation: fill 1.7s ease-out infinite;
    }
  }
  @keyframes fill {
    0% {
      mask-position: 100% 100%;
    }
    100% {
      mask-position: 100% 0%;
    }
  }
}

.operation-tab {
  display: flex;
  gap: var(--dsx-space-3);
}

.operation-form.hidden {
  position: absolute;
  visibility: visible;
  opacity: 0;
  pointer-events: none;
}

.learning-field {
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
  .l-field {
    flex: 0 0 calc(50% - 8px);
  }
  .l-label {
    margin-bottom: 8px;
  }
  .dsx-ButtonArea {
    width: 100%;
  }
}

.learning-edit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -12px 0 12px;
  .dsx-Checkbox {
    font-weight: var(--dsx-font-weight-semibold);
  }
}

.learning-list {
  .learning-item {
    display: grid;
    grid-template-rows: min-content 0fr;
    overflow: hidden;
    margin-top: 12px;
    padding: 15px 0;
    border: 1px solid #eaebec;
    border-radius: var(--dsx-radius-xxlarge);
    background-color: #f8f9fb;
    transition: grid-template-rows var(--dsx-transition-collapse);
    &:first-child {
      margin-top: 0;
    }
    &.is-active {
      grid-template-rows: min-content 1fr;
    }
    .item-head {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 15px;
      .dsx-IconButton {
        color: #70737c;
      }
    }
    .item-control {
      margin-left: auto;
      transition: transform var(--dsx-transition-base);
      &[aria-expanded='true'] {
        transform: rotate(-180deg);
      }
    }
    .item-edit {
      display: flex;
      gap: 4px;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 48px;
      transition: var(--dsx-transition-base);
    }
    .item-body {
      min-height: calc(1em * var(--dsx-line-height-base));
      margin-top: 8px;
      padding: 0 15px;
      overflow: hidden;
      .item-data {
        margin: 0 -15px;
        padding: 0 15px;
        &:not(:first-child) {
          margin-top: 15px;
          padding-top: 15px;
          border-top: 1px solid #eaebec;
        }
      }
      .dsx-Text {
        max-height: calc(10em * var(--dsx-line-height-base));
        overflow-y: auto;
      }
    }
    &:not(.is-active) {
      .item-body {
        .dsx-Text {
          @include ellipsis(1);
        }
      }
    }
    &:hover .item-edit,
    &:has(:focus-visible) .item-edit {
      opacity: 1;
    }
  }
}
// 202411 업데이트
/* api 연동폼 */
.learning-list2 {
  .learning-item {
    display: grid;
    grid-template-rows: min-content 0fr;
    overflow: hidden;
    margin-top: 12px;
    padding: 20px 0;
    border: 1px solid #eaebec;
    border-radius: var(--dsx-radius-xxlarge);
    background-color: #f8f9fb;
    transition: grid-template-rows var(--dsx-transition-collapse);
    &:first-child {
      margin-top: 0;
    }
    &.is-active {
      grid-template-rows: min-content 1fr;
    }
    .item-head {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 20px;
      .dsx-IconButton {
        color: #70737c;
      }
    }
    .item-control {
      margin-left: auto;
      transition: transform var(--dsx-transition-base);
      &[aria-expanded='true'] {
        transform: rotate(-180deg);
      }
    }
    .item-name {
      position: relative;
      input {
        width: 100%;
      }
    }
    .item-edit {
      display: flex;
      gap: 4px;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 48px;
      transition: var(--dsx-transition-base);
    }
    .item-body {
      min-height: 0;
      padding: 0 20px;
      overflow: hidden;
      .item-data {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eaebec;
      }
    }
    &:hover .item-edit,
    &:has(:focus-visible) .item-edit {
      opacity: 1;
    }
  }
  .frm-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 8px;
    .frm-bind {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
}

/* 템플릿 업로드 학습 */
.learning-upload {
  padding: 20px;
  border: 1px solid #eaebec;
  border-radius: var(--dsx-radius-xxlarge);
  background-color: #f8f9fb;
}

/* 업로드 파일 뷰어 */
.upload-fileView {
  margin-top: var(--dsx-space-5);
  border-radius: var(--dsx-radius-xxlarge);
  outline: 1px solid var(--dsx-color-neutral-border-alternative);
  outline-offset: -1px;
  overflow: hidden;
  &-head {
    display: flex;
    align-items: center;
    gap: var(--dsx-space-3);
    padding: var(--dsx-space-3) var(--dsx-space-6);
    color: var(--dsx-color-inverse-text);
    background-color: #2e2f33;
  }
  &-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    .dsx-IconButton + .dsx-IconButton {
      margin-left: -8px;
    }
  }
  &-code {
    color: #fff;
    background-color: #171719;
    font-family: var(--dsx-font-family-code);
    font-size: var(--dsx-font-size-large);
    max-height: 509px;
    overflow-y: auto;
  }
  .file-name {
    font-size: var(--dsx-font-size-body2);
    font-weight: var(--dsx-font-weight-medium);
  }
  .file-size {
    font-size: var(--dsx-font-size-label2);
  }
  &-sheet {
    height: 512px;
    background-color: var(--dsx-color-neutral-surface-default);
  }
}

.sheet-table {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  background-color: var(--dsx-color-neutral-surface-default);
  overflow: auto;

  &::-webkit-scrollbar {
    border: 1px solid #eaebec;
  }
  .sheet-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 var(--dsx-space-4);

    .sheet-cell {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: var(--dsx-space-2) var(--dsx-space-3);
      text-align: left;
      white-space: normal;
      overflow-wrap: anywhere;
      &:focus {
        outline: 1px solid var(--dsx-color-primary-border-default);
        outline-offset: -1px;
      }
    }

    &.sheet-head {
      position: sticky;
      top: 0;
      min-height: rem(30px);
      font-weight: var(--dsx-font-weight-semibold);
      background-color: #eeeef1;
      border: solid var(--dsx-color-neutral-border-alternative);
      border-width: 0 1px;
    }

    &.sheet-data {
      min-height: rem(48px);
      border-bottom: 1px solid #eaebec;
      .sheet-ctrl {
        display: none;
        padding: var(--dsx-space-2) var(--dsx-space-3);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        .sheet-ctrl {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
  }
}
/* [프롬프트 템플릿] 파일 업로더 */
.upload-container {
  margin-top: var(--dsx-space-8);
}

.upload-fileList {
  &-item {
    min-width: 0;
    margin-top: var(--dsx-space-3);
    padding: 12px 16px;
    @include flex($v: center);
    gap: 8px;
    border-radius: var(--dsx-radius-xlarge);
    border: 1px solid var(--dsx-color-neutral-border-alternative);
    background-color: #f8f9fb;
  }
  &-controls {
    margin-left: auto;
    flex-shrink: 0;
    @include flex($v: center);
    gap: 10px;
  }
  .file-icon {
    width: 28px;
    padding: 6px 5px;
    aspect-ratio: 1;
    flex-shrink: 0;
    border-radius: var(--dsx-radius-max);
    background-color: var(--dsx-color-normal-fill-default);
    overflow: hidden;
    > img {
      width: 16px;
    }
  }
  .file-name {
    font-size: var(--dsx-font-size-body2);
    font-weight: var(--dsx-font-weight-medium);
    @include ellipsis(1);
  }
  .file-size {
    flex-shrink: 0;
    font-size: var(--dsx-font-size-label2);
    color: var(--dsx-color-neutral-text-alternative);
    line-height: 1;
  }
  .file-delete {
    color: var(--dsx-color-normal-icon-default);
  }
}
// 202411 업데이트
/* api 연동폼 */
.learning-list2 {
  .learning-item {
    display: grid;
    grid-template-rows: min-content 0fr;
    overflow: hidden;
    margin-top: 12px;
    padding: 20px 0;
    border: 1px solid #eaebec;
    border-radius: var(--dsx-radius-xxlarge);
    background-color: #f8f9fb;
    transition: grid-template-rows var(--dsx-transition-collapse);
    &:first-child {
      margin-top: 0;
    }
    &.is-active {
      grid-template-rows: min-content 1fr;
    }
    .item-head {
      position: relative;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 0 20px;
      .dsx-IconButton {
        color: #70737c;
      }
    }
    .item-control {
      margin-left: auto;
      transition: transform var(--dsx-transition-base);
      &[aria-expanded='true'] {
        transform: rotate(-180deg);
      }
    }
    .item-name {
      position: relative;
      input {
        width: 100%;
      }
    }
    .item-edit {
      display: flex;
      gap: 4px;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 48px;
      transition: var(--dsx-transition-base);
    }
    .item-body {
      min-height: 0;
      padding: 0 20px;
      overflow: hidden;
      .item-data {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eaebec;
      }
    }
    &:hover .item-edit,
    &:has(:focus-visible) .item-edit {
      opacity: 1;
    }
  }
  .frm-panel {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 8px;
    .frm-bind {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
}

/* 템플릿 업로드 학습 */
.learning-upload {
  padding: 20px;
  border: 1px solid #eaebec;
  border-radius: var(--dsx-radius-xxlarge);
  background-color: #f8f9fb;
}

/* 업로드 파일 뷰어 */
.upload-fileView {
  margin-top: var(--dsx-space-5);
  border-radius: var(--dsx-radius-xxlarge);
  outline: 1px solid var(--dsx-color-neutral-border-alternative);
  outline-offset: -1px;
  overflow: hidden;
  &-head {
    display: flex;
    align-items: center;
    gap: var(--dsx-space-3);
    padding: var(--dsx-space-3) var(--dsx-space-6);
    color: var(--dsx-color-inverse-text);
    background-color: #2e2f33;
  }
  &-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: auto;
    .dsx-IconButton + .dsx-IconButton {
      margin-left: -8px;
    }
  }
  &-code {
    color: #fff;
    background-color: #171719;
    font-family: var(--dsx-font-family-code);
    font-size: var(--dsx-font-size-large);
    max-height: 509px;
    overflow-y: auto;
  }
  .file-name {
    font-size: var(--dsx-font-size-body2);
    font-weight: var(--dsx-font-weight-medium);
  }
  .file-size {
    font-size: var(--dsx-font-size-label2);
  }
  &-sheet {
    overflow: auto;
    height: 512px;
    background-color: var(--dsx-color-neutral-surface-default);
  }
}

.sheet-table {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  background-color: var(--dsx-color-neutral-surface-default);
  overflow: auto;

  &::-webkit-scrollbar {
    border: 1px solid #eaebec;
  }
  .sheet-row {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0 var(--dsx-space-4);

    .sheet-cell {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      padding: var(--dsx-space-2) var(--dsx-space-3);
      text-align: left;
      white-space: normal;
      overflow-wrap: anywhere;
      &:focus {
        outline: 1px solid var(--dsx-color-primary-border-default);
        outline-offset: -1px;
      }
    }

    &.sheet-head {
      position: sticky;
      top: 0;
      min-height: rem(30px);
      font-weight: var(--dsx-font-weight-semibold);
      background-color: #eeeef1;
      border: solid var(--dsx-color-neutral-border-alternative);
      border-width: 0 1px;
    }

    &.sheet-data {
      min-height: rem(48px);
      border-bottom: 1px solid #eaebec;
      .sheet-ctrl {
        display: none;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        .sheet-ctrl {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
    &.sheet-head .sheet-cell:first-child,
    &.sheet-data .sheet-cell:first-child {
      flex: none;
      width: 50px;
    }
  }
}
