@import '../config/index.scss';

.studio-read,
.member-select-view {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: auto;
  max-width: rem(1100px);
  max-height: rem(780px);
  padding: 0;
  border-radius: calc(var(--dsx-radius-base) * 5);
  overflow-y: auto;
}
.read-detail {
  flex: 1 1 50%;
  width: rem(680px);
  padding: 32px;
  .det-head {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 28px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaebec;
    .dsx-Avatar {
      flex: 0 0 auto;
    }
    .d-text {
      display: flex;
      flex-direction: column;
      gap: 6px;
      min-height: rem(56px);
    }
    .d-utils {
      flex: 0 0 auto;
      margin-left: auto;
    }
  }
  .det-body {
    .d-desc {
      height: rem(120px);
      font-size: var(--dsx-font-size-body2);
      margin: 0;
    }
    .d-keyword {
      display: flex;
      gap: 6px;
      margin-top: 16px;
    }
    .dsx-cont-box {
      // max-height: rem(212px); //25.02.18-pys 높이가 이상해서 없앰
      overflow-y: auto;
    }
  }
  .det-foot {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    padding: 12px 0;
    border-top: 1px solid #eaebec;
    .d-date {
      display: flex;
      align-items: center;
      gap: 8px;
      & > li {
        padding: 4px 8px;
        font-size: var(--dsx-font-size-label2);
        border-radius: var(--dsx-radius-base);
        background-color: var(--dsx-color-neutral-fill-alternative);
      }
      .tit::after {
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: -2px 6px 0;
        vertical-align: middle;
        background-color: var(--dsx-color-neutral-border-default);
      }
      .con {
        font-weight: var(--dsx-font-weight-medium);
      }
    }
  }
}
.read-preview {
  flex: 0 0 auto;
  width: rem(500px);
  display: flex;
  flex-direction: column;
  border-left: 1px solid #eaebec;
  overflow-y: auto;
  .view-chat {
    position: relative;
    padding: 0;
    background-color: var(--dsx-color-primary-surface-default);
    .chat-history {
      // 좌측 콘텐츠 높이에 따라 유동적으로 적용되도록 반영
      position: absolute;
      top: 0;
      left: 32px;
      right: 32px;
    }
    .dsx-Avatar {
      --dsx-avatar-size: #{rem(24px)};
    }
    .chat-message {
      .msg-profile {
        .name {
          font-size: var(--dsx-font-size-body2);
        }
      }
      .msg-content {
        padding-left: rem(32px);
        font-size: var(--dsx-font-size-base);
      }
    }
  }
}
.read-close {
  order: -1;
  display: flex;
  justify-content: flex-end;
  padding: 12px 20px;
  border-bottom: 1px solid #eaebec;
}

.question-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: rem(150px);
  gap: 0px;
  padding: 5px;
  background-color: var(--ds-color-surface-paper);
  .quest {
    padding: 8px 16px;
    border-radius: var(--dsx-radius-large);
    background-color: var(--dsx-color-white);
    margin: 5px;
  }
}

.prompt-data {
  max-height: rem(150px);
}

.member-search {
  margin-bottom: 20px;
}

.member-list {
  .member-item {
    display: grid;
    grid-template-rows: min-content 0fr;
    overflow: hidden;
    margin-top: 12px;
    padding: 15px 0;
    border: 1px solid #eaebec;
    border-radius: var(--dsx-radius-xxlarge);
    background-color: #f8f9fb;
    transition: grid-template-rows var(--dsx-transition-collapse);
    &:first-child {
      margin-top: 0;
    }
    .dsx-Checkbox {
      padding: 0 12px;
    }
    .dsx-Checkbox input {
      margin: auto 0;
    }
  }
}
.member-select-option {
  margin: 0 0 12px;
}
