.dsx-Accordion {
    border-top: 1px solid var(--dsx-color-neutral-border-neutral);
    &-item {
      display: grid;
      grid-template-rows: min-content 0fr;
      overflow: hidden;
      border-bottom: 1px solid var(--dsx-color-neutral-border-neutral);
      transition: grid-template-rows var(--dsx-transition-collapse);
      &[data-state="active"] {
        grid-template-rows: min-content 1fr;
      }
    }
    &-header {
      position: relative;
      min-height: rem(48, 16);
      font-size: var(--dsx-font-size-body1);
      font-weight: var(--dsx-font-weight-medium);
    }
    &-control {
      --dsx-icon-size: rem(20, 16);
      display: flex;
      align-items: center;
      gap: var(--dsx-space-3);
      width: 100%;
      height: 100%;
      padding: var(--dsx-space-3) var(--dsx-space-6);
      .dsx-Icon--chevronBottom {
        transition: transform var(--dsx-transition-base);
      }
      &[aria-expanded="true"] {
        .dsx-Icon--chevronBottom {
          transform: rotate(-180deg);
        }
      }
      .accordion-title {
        margin-right: auto;
      }
    }
    &-container {
      min-height: 0;
    }
    &-content {
      padding: var(--dsx-space-3) var(--dsx-space-6) var(--dsx-space-5);
    }
  }