/* Carousel - swiperjs */
@import '../config/index.scss';

.dsx-Carousel {
  --dsx-carousel-slide-width: 80%;
  --dsx-carousel-slide-height: 100%;
  --dsx-carousel-navigation-size: #{rem(48px)};
  --dsx-carousel-navigation-spacing: 0px;
  position: relative;
  height: 100%;
  padding: 0 rem(72, 16);
  &-prev, &-next {
    --dsx-icon-size: var(--dsx-carousel-navigation-size);
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 8px));
    color: var(--dsx-color-normal-icon-neutral);
    &:not(:disabled):hover {
      color: var(--dsx-color-neutral-icon-neutral);
    }
  }
  &-prev {
    left: var(--dsx-carousel-navigation-spacing);
  }
  &-next {
    right: var(--dsx-carousel-navigation-spacing);
  }
  
  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;     
    box-sizing: content-box;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 0;
  }
  .swiper-slide {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--dsx-carousel-slide-width);
    height: var(--dsx-carousel-slide-height);
    max-height: rem(400, 16);
    overflow: hidden;
    text-align: center;
    // background-color: var(--dsx-color-info-fill-neutral);
    &>a:focus-visible {
      outline-offset: -2px;
    }
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-button-lock {
    display: none;
  }

  .swiper-pagination {
    position: relative;
    margin-top: 16px;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 0;
    &.swiper-pagination-horizontal {
      @include flex(flex, center, center);
      gap: var(--dsx-space-3);
    }
    &.swiper-pagination-lock {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    width: rem(8, 16);
    height: rem(8, 16);
    margin: rem(-4, 16);
    border: rem(4, 16) solid transparent;
    border-radius: var(--dsx-radius-max);
    background-color: var(--dsx-color-interaction-fill-inactive);
    background-clip: padding-box;
    box-sizing: content-box;
    transition: var(--dsx-transition-base);
  }
  .swiper-pagination-bullet-active {
    width: rem(16, 16);
    background-color: var(--dsx-color-primary-fill-default);
  }
  .swiper-pagination-clickable {
    .swiper-pagination-bullet {
      cursor: pointer;
    }
  }
  .swiper-controls {
    position: absolute;
    top: 0;
  }

  // variant
  &--fill {
    --dsx-carousel-navigation-spacing: 40px;
    padding: 0;
  }

  // mobile
  @include mobile {
    &-prev, &-next {
      display: none;
    }
  }
}