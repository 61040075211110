.websearch-result {
  padding-left: var(--ds-chat-history-spacing);
  margin-top: 4px;
  padding-bottom: 1rem;
}

.websearch-result .search-img {
  // width: 47.4vw;
  padding: 0 var(--dsx-space-7);
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  grid-template-rows: 80px 80px;

  .image-item {
    &:first-child {
      grid-row: span 2 / span 2;
      grid-column: span 2 / span 2;
    }

    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: var(--dsx-radius-xlarge);

    &:hover {
      .image-description {
        opacity: 1;
        position: absolute;
        align-items: center;
        display: flex;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        inset: 0;
        color: var(--ds-color-text-inverse);
      }
    }
  }

  img {
    border-radius: var(--dsx-radius-xlarge);
  }

  .image-more {
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    inset: 0;
    color: var(--ds-color-text-inverse);
  }
  .image-description {
    display: none;
  }
}

.image-viewer {
  height: 500px;

  .dsx-Dialog-content {
    height: 350px;
    align-items: center;
    justify-content: center;
    display: flex;
    .viewer-container {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .image-scroller {
        width: 100%;
        text-align: center;
        overflow-y: scroll;
      }
      .description {
        padding-top: 12px;
        margin-top: 12px;
        position: sticky;
        bottom: 0;
        border-top: 1px solid #eaebec;
      }
    }
  }

  .dsx-Dialog-footer {
    margin-top: auto;
  }
}
.websearch-result .is-skeleton {
  border-color: #f4f4f5;
  width: 100%;
  border-radius: var(--dsx-radius-xlarge);
  @include skeleton;

  &.search-img {
    height: 150px;
  }
}

.websearch-result .result-card {
  .card-label {
    text-align: left;
    display: flex;
    gap: var(--dsx-space-2);
    align-items: center;

    .label {
      margin-bottom: 0.2rem;
    }
  }
  &.disabled{
    pointer-events: none;
  }
}

.websearch-result > .result-card > .dsx-Accordion {
  --websearch-result-text-color: inherit;
  --websearch-result-border-color: var(--ds-color-border-subtler);
  --websearch-result-icon-color: var(--ds-color-fill-strong);
  border: 1px solid var(--websearch-result-border-color);
  border-radius: var(--ds-border-radius-lg);
}

.websearch-result > .result-card > .dsx-Accordion > .dsx-Accordion-item[data-state='active'] > .dsx-Accordion-header {
  border-bottom: 1px solid var(--websearch-result-border-color);
}

.websearch-result > .result-card > .dsx-Accordion > .dsx-Accordion-item {
  border-bottom: none;
  .dsx-Accordion-header {
    .dsx-Accordion-control {
      padding: var(--dsx-space-3) var(--dsx-space-7);
    }
  }
  .dsx-Accordion-container {
    .dsx-Accordion-content {
      padding: var(--dsx-space-3) 0 var(--dsx-space-5);
      .search-keyword {
        padding: 0 var(--dsx-space-7);
      }
      .search-contents {
        .dsx-Carousel {
          padding: 0 var(--dsx-space-7);
          .dsx-IconButton {
            padding: 0;
            margin: 0;
            &.dsx-Carousel-prev {
              left: 0;
            }
            &.dsx-Carousel-next {
              right: 0;
            }
            .dsx-Icon::before {
              width: 0.5em; //width:24px
              mask-size: 200%;
            }
          }
        }
      }
    }
  }
}

.websearch-result .dsx-Carousel {
  width: 100%;

  .dsx-Carousel-prev,
  .dsx-Carousel-next {
    display: none;
  }

  // .dsx-Carousel-prev {
  //   left: 4px;
  // }

  // .dsx-Carousel-next {
  //   right: 4px;
  // }

  &:hover {
    .dsx-Carousel-prev,
    .dsx-Carousel-next {
      display: inherit;
      color: var(--dsx-color-interaction-fill-inactive);

      &:not(:disabled):hover {
        // &:not(:disabled){
        //   color: var(--dsx-color-neutral-icon-neutral);
        // }
        color: var(--dsx-color-primary-fill-default);
        // color: var(--dsx-color-neutral-icon-neutral);
      }
    }
  }
}

.search-result {
  display: grid;
}

.search-result .search-contents {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  margin-top: var(--dsx-space-3);
  // width: 44.5vw;
  .swiper {
    height: fit-content;
  }
}

.search-result .search-keyword {
  /* padding-bottom: .25rem; */
  overflow-x: auto;
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--dsx-space-3);
  height: 100%;
  // width: fit-content;
}

.search-result .search-contents .contents-card.is-skeleton {
  border-color: #f4f4f5;
  width: 447px;
  height: 138.5px;
  border-radius: var(--dsx-radius-xlarge);
  @include skeleton;

  &.academic {
    height: 170px;
  }

  &.youtube {
    height: 335.99px;
  }
}

.search-result .search-contents .contents-card .card-header {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 0.75rem;

  .card-text .dsx-Icon {
    font-size: inherit;
  }
}

.search-result .search-contents .contents-card.web img {
  width: 16px;
  height: 16px;
}

.search-result .search-contents .contents-card .card-header .header-content,
.search-result .search-contents .contents-card .card-header .header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result .search-contents .contents-card .card-header .header-content .header-url {
  display: flex;
  gap: 0.25rem;
}

.search-result .search-contents .contents-card {
  .card-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }

  .card-author {
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;

    .author {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      display: block;
    }
  }

  .result-meta {
    display: flex;
    gap: 0.5rem;
  }

  .result-badge {
    margin-bottom: var(--dsx-space-3);
  }

  .tooltip-wrap {
    display: block;

    .ds-tooltip {
      .tooltip-desc {
        white-space: normal;
      }
    }
  }

  &.youtube {
    .card-text {
      min-height: 54px;
    }
  }

  &.memory {
    .card-text {
      overflow: scroll;
      text-overflow: inherit;
      min-height: 98px;
    }
  }

  .dsx-Card-media {
    display: block;
  }
}

.search-result .search-contents .swiper-slide {
  overflow: inherit;

  .dsx-Card {
    overflow: inherit;

    .ds-tooltip {
      overflow: visible;
    }
  }

  .card-author,
  .card-publishedDate {
    text-align: left;
  }

  text-align: left;
}

@media (pointer: coarse) and (max-width: 768px) {
  .websearch-result .search-img {
    grid-template-rows: 100px;
    padding: 0 var(--dsx-space-7);
    .image-item {
      &:first-child {
        grid-row: 1;
        grid-column: 1;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
