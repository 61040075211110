@import '../config/index.scss';

.dsx-Mark {
  background: none;
  &--bold {
    font-weight: inherit;
  }
  &--fill {
    padding: 2px 4px;
    &.dsx-color-primary {
      background-color: var(--dsx-color-primary-fill-neutral);
    }
    &.dsx-color-positive {
      background-color: var(--dsx-color-positive-fill-neutral);
    }
    &.dsx-color-negative {
      background-color: var(--dsx-color-negative-fill-neutral);
    }
    &.dsx-color-caution {
      background-color: var(--dsx-color-caution-fill-neutral);
    }
    &.dsx-color-info {
      background-color: var(--dsx-color-info-fill-neutral);
    }
  }
  &--gradiant {
    background: linear-gradient(91deg, #00aeff 0%, #7d5ef7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
